import React, { useState } from 'react'
import { Flex, Box, Text, VStack, HStack, IconButton, Button, useDisclosure, Stack, Divider } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { updateRSVP, postInvite, postReminder } from '../../../../../../api/actions'
import { impersonateUser } from '../../../../../../api/admin/users'
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from '../../../../atoms/Icon'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RsvpStatus, { MessageStatus } from '../RsvpStatus'

const ArrowButton = ({ direction, ...rest }) => {
	let icon
	if (direction == 'up') {
		icon = <KeyboardArrowUpRounded width="2rem" color="link" />
	} else icon = <KeyboardArrowDownRounded color="link" />

	return (
		<IconButton
			icon={icon}
			sx={{
				'w': '2rem',
				'h': '2rem',
				'maxH': '2rem',
				'maxW': '2rem',
				'minW': '2rem',
				'backgroundColor': 'rgba(205, 231, 255, 0.8)',
				'&:hover': {
					backgroundColor: 'blue.50',
				},
				'svg': {
					fill: 'var(--vidday-colors-link)',
				},
			}}
			{...rest}
		/>
	)
}

const buttonStyles = {
	_focus: {
		boxShadow: 'none',
	},
}

const RSVPItem = ({ item, mediaId, role, isImpersonated, isMinimized }) => {
	const { id } = item
	const name = `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const { uuid } = useParams()
	const rsvpDrawer = useDisclosure()

	const handleSetTo = (status) => {
		dispatch(updateRSVP({ id: id, status: status }))
	}
	const handleArchive = () => {
		dispatch(updateRSVP({ id: id, archived: true }))
	}
	const handleUnArchive = () => {
		dispatch(updateRSVP({ id: id, archived: false }))
	}
	const handleResendInvite = () => {
		dispatch(postInvite({ id: id }))
	}
	const handleResendReminder = () => {
		dispatch(postReminder({ id: id }))
	}
	const handleGoToMedia = () => {
		if (mediaId) {
			navigate(`/event/${uuid}/media/${mediaId}`, {
				state: { backDestination: location.pathname },
			})
		}
	}

	const handleImpersonateUser = (userId) => {
		// if in browser, use current URL; if SSR, send back undefined to use the default
		let redirect = `/settings/profile`
		if (userId) dispatch(impersonateUser(userId, redirect))
	}

	const truncate = {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: 'fill-available',
		display: 'block',
	}

	return (
		<Box w="full">
			<Flex justify="space-between">
				<VStack
					alignItems="flex-start"
					spacing="0"
					sx={{
						overflow: 'hidden',
					}}>
					<Text sx={truncate} fontSize="0.875rem" fontWeight="bold" noOfLines={1}>
						{name}
					</Text>
					<Stack spacing="0" direction={['column', 'row']} justifyItems={['left', 'center']}>
						<HStack>
							{item.deliverySms && (
								<MessageStatus
									status={item.smsMessages?.length ? item.smsMessages[0].status : 'queued'}>
									<Text sx={truncate} fontSize="0.75rem" noOfLines={1}>
										{item.deliverySms}
									</Text>
								</MessageStatus>
							)}
						</HStack>
						<HStack>
							{item.deliveryEmail && (
								<MessageStatus
									status={
										item.status == 'participated' || item.status == 'completed'
											? 'delivered'
											: item.emailStatus
									}>
									<Text sx={truncate} fontSize="0.75rem" noOfLines={1}>
										{item.deliveryEmail}
									</Text>
								</MessageStatus>
							)}
						</HStack>
					</Stack>
				</VStack>
				<Flex align="center" ml="1rem" borderLeft="1px solid #eee" paddingLeft={['.5rem', '1rem']}>
					<RsvpStatus item={item} onToggle={rsvpDrawer.onToggle} handleGoToMedia={handleGoToMedia} />
					{!isMinimized && (
						<ArrowButton
							ml="1rem"
							direction={rsvpDrawer.isOpen ? 'up' : 'down'}
							onClick={rsvpDrawer.onToggle}
						/>
					)}
				</Flex>
			</Flex>
			{rsvpDrawer.isOpen && (
				<Box
					w="full"
					borderTopWidth="1px"
					borderTopStyle="dashed"
					borderTopColor="#DADDE1"
					mt="0.5rem"
					pt="0.75rem"
					pb="0.5rem">
					<HStack
						w="full"
						spacing={['0.75rem', '0.75rem']}
						overflowX="auto"
						sx={{
							'scrollbarWidth': 'none',
							' msOverflowStyle': 'none',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							'& *': {
								minW: 'auto !important',
							},
						}}>
						{!item.archived && (
							<>
								<Button sx={buttonStyles} size="xs" variant="link" onClick={() => handleResendInvite()}>
									Resend Invite
								</Button>
								<Button
									sx={buttonStyles}
									size="xs"
									variant="link"
									onClick={() => handleResendReminder()}>
									Send Reminder
								</Button>
								<Button
									sx={buttonStyles}
									size="xs"
									variant="link"
									onClick={() => handleSetTo(item.status != 'completed' ? 'completed' : 'confirmed')}>
									{item.status != 'completed' ? `Mark as Participated` : `Mark as RSVP'd`}
								</Button>
								<Button
									sx={buttonStyles}
									size="xs"
									variant="link"
									colorScheme="red"
									onClick={() => handleArchive()}>
									Remove
								</Button>
								{role == 'admin' && !isImpersonated && (
									<Button
										sx={buttonStyles}
										size="xs"
										variant="link"
										onClick={() => handleImpersonateUser(item.userId)}>
										Impersonate User
									</Button>
								)}
							</>
						)}
						{item.archived && (
							<Button sx={buttonStyles} size="xs" variant="link" onClick={() => handleUnArchive()}>
								Un-archive
							</Button>
						)}
					</HStack>
				</Box>
			)}
		</Box>
	)
}

export default RSVPItem
